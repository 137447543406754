import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Alert = makeShortcode("Alert");
const AlertTitle = makeShortcode("AlertTitle");
const Link = makeShortcode("Link");
const Code = makeShortcode("Code");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`It's trivial to install the `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` binary on your local machine.`}</p>
    <Alert severity="info" mdxType="Alert">
  <AlertTitle mdxType="AlertTitle">Building from source?</AlertTitle>
  <div>
    Check out our guide to{' '}
    <Link href="https://github.com/smallstep/certificates/blob/master/CONTRIBUTING.md" mdxType="Link">getting
    started with development</Link>.
  </div>
    </Alert>
    <h4>{`Select your operating system or infrastructure:`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#macos"
        }}>{`macOS`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#linux-packages-amd64"
        }}>{`Linux Packages`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#debian"
            }}>{`Debian`}</a>{`   `}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#arch-linux"
            }}>{`Arch Linux`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#redhat"
            }}>{`RedHat`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#alpine-linux"
            }}>{`Alpine Linux`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#nixos"
            }}>{`NixOS`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#freebsd"
            }}>{`FreeBSD`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#linux-binaries"
        }}>{`Linux binaries`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#kubernetes"
        }}>{`Kubernetes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#docker"
        }}>{`Docker`}</a></li>
    </ul>
    <h3>{`macOS`}</h3>
    <p>{`Install `}<inlineCode parentName="p">{`step`}</inlineCode>{` and `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` together, via `}<a parentName="p" {...{
        "href": "https://brew.sh/"
      }}>{`Homebrew`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`brew install step
`}</code></pre>
    <h3>{`Linux Packages (amd64)`}</h3>
    <Alert severity="info" mdxType="Alert">
  <AlertTitle mdxType="AlertTitle">Install both <Code mdxType="Code">step-ca</Code> and the <Code mdxType="Code">step</Code> CLI tool.</AlertTitle>
  <div>
    The <Link href="/docs/step-cli" mdxType="Link"><Code mdxType="Code">step</Code> CLI tool</Link> is the easiest way to
    initialize, configure, and control <Code mdxType="Code">step-ca</Code>. While <Code mdxType="Code">step</Code> is not
    technically required to run <Code mdxType="Code">step-ca</Code>, it is very much recommended.
  </div>
    </Alert>
    <h4>{`Debian`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Install `}<inlineCode parentName="p">{`step`}</inlineCode></p>
        <p parentName="li">{`Download and install the Debian package for your platform from our `}<a parentName="p" {...{
            "href": "https://github.com/smallstep/cli/releases/latest"
          }}>{`latest release`}</a>{`:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`wget https://dl.step.sm/gh-release/cli/docs-ca-install/v0.23.1/step-cli_0.23.1_amd64.deb
sudo dpkg -i step-cli_0.23.1_amd64.deb
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Install `}<inlineCode parentName="p">{`step-ca`}</inlineCode></p>
        <p parentName="li">{`Download and install the Debian package for your platform from our `}<a parentName="p" {...{
            "href": "https://github.com/smallstep/certificates/releases/latest"
          }}>{`latest release`}</a>{`:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`wget https://dl.step.sm/gh-release/certificates/docs-ca-install/v0.23.1/step-ca_0.23.1_amd64.deb
sudo dpkg -i step-ca_0.23.1_amd64.deb
`}</code></pre>
      </li>
    </ol>
    <p>{`To configure `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` as a daemon, see our `}<a parentName="p" {...{
        "href": "https://smallstep.com/docs/step-ca/certificate-authority-server-production#running-step-ca-as-a-daemon"
      }}>{`systemd configuration guide`}</a>{`.`}</p>
    <p>{`To uninstall, run `}<inlineCode parentName="p">{`dpkg -r step-cli step-ca`}</inlineCode>{` and remove the configuration directory `}<inlineCode parentName="p">{`$HOME/.step`}</inlineCode>{`.`}</p>
    <h4>{`Arch Linux`}</h4>
    <p>{`There are community-driven Arch Linux packages.`}</p>
    <p>{`You can use `}<a parentName="p" {...{
        "href": "https://www.archlinux.org/pacman/"
      }}>{`pacman`}</a>{` to install the packages.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`pacman -S step-cli step-ca
`}</code></pre>
    <p>{`The binary tarballs can be found here:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://archlinux.org/packages/community/x86_64/step-ca/"
        }}><inlineCode parentName="a">{`step-ca`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://archlinux.org/packages/community/x86_64/step-cli/"
        }}><inlineCode parentName="a">{`step-cli`}</inlineCode></a></li>
    </ul>
    <p>{`Big shout out to the maintainers of these packages! We appreciate you.`}</p>
    <p>{`To uninstall, run `}<inlineCode parentName="p">{`pacman -R step-ca step-cli`}</inlineCode>{` and remove the configuration directory `}<inlineCode parentName="p">{`$HOME/.step`}</inlineCode>{`.`}</p>
    <h4>{`RedHat`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Install `}<inlineCode parentName="p">{`step`}</inlineCode></p>
        <p parentName="li">{`Download and install the RPM package for your platform from our `}<a parentName="p" {...{
            "href": "https://github.com/smallstep/cli/releases/latest"
          }}>{`latest release`}</a>{`:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`wget https://dl.step.sm/gh-release/cli/docs-ca-install/v0.23.1/step-cli_0.23.1_amd64.rpm
sudo dnf install step-cli_0.23.1_amd64.rpm
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Install `}<inlineCode parentName="p">{`step-ca`}</inlineCode></p>
        <p parentName="li">{`Download and install the RPM package for your platform from our `}<a parentName="p" {...{
            "href": "https://github.com/smallstep/certificates/releases/latest"
          }}>{`latest release`}</a>{`:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`wget https://dl.step.sm/gh-release/certificates/docs-ca-install/v0.23.1/step-ca_0.23.1_amd64.rpm
sudo dnf install step-ca_0.23.1_amd64.rpm
`}</code></pre>
      </li>
    </ol>
    <p>{`To configure `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` as a daemon, see our `}<a parentName="p" {...{
        "href": "https://smallstep.com/docs/step-ca/certificate-authority-server-production#running-step-ca-as-a-daemon"
      }}>{`systemd configuration guide`}</a>{`.`}</p>
    <p>{`To uninstall, run `}<inlineCode parentName="p">{`dnf remove step-cli step-ca`}</inlineCode>{` and remove the configuration directory `}<inlineCode parentName="p">{`$HOME/.step`}</inlineCode>{`.`}</p>
    <h4>{`Alpine Linux`}</h4>
    <Alert severity="info" mdxType="Alert">
    <AlertTitle mdxType="AlertTitle">
        This package is in a testing repository.
    </AlertTitle>
    <div>
      To install it, you'll need to append <code>http://dl-cdn.alpinelinux.org/alpine/edge/testing</code> to <code>/etc/apk/repositories</code>.
      Alternatively, see <a href="#linux-other">Linux (other)</a>.
    </div>
    </Alert>
    <p>{`There are community driven Alpine Linux Packages for `}<inlineCode parentName="p">{`step`}</inlineCode>{` and `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`.`}</p>
    <p>{`Use `}<a parentName="p" {...{
        "href": "https://wiki.alpinelinux.org/wiki/Alpine_Linux_package_management"
      }}>{`apk`}</a>{` to install `}<inlineCode parentName="p">{`step`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{}}>{`apk add step-cli step-certificates
`}</code></pre>
    <p>{`To uninstall, run `}<inlineCode parentName="p">{`apk del step-cli step-certificates`}</inlineCode>{` and remove the `}<inlineCode parentName="p">{`$HOME/.step`}</inlineCode>{` configuration directory.`}</p>
    <p>{`The binary tarballs can be found here:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://pkgs.alpinelinux.org/package/edge/testing/x86/step-cli"
        }}><inlineCode parentName="a">{`step-cli`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://pkgs.alpinelinux.org/package/edge/testing/x86/step-certificates"
        }}><inlineCode parentName="a">{`step-certificates`}</inlineCode></a></li>
    </ul>
    <p>{`Big shout out to the maintainers of these packages! We appreciate you.`}</p>
    <h4>{`NixOS`}</h4>
    <p>{`There are community-maintained packages for NixOS:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://search.nixos.org/packages?show=step-ca&from=0&size=50&sort=relevance&query=step-ca"
        }}><inlineCode parentName="a">{`step-ca`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://search.nixos.org/packages?show=step-cli&from=0&size=50&sort=relevance&query=step-cli"
        }}><inlineCode parentName="a">{`step-cli`}</inlineCode></a></li>
    </ul>
    <h4>{`FreeBSD`}</h4>
    <p>{`There are community-maintained packages for FreeBSD:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.freshports.org/security/step-certificates/"
        }}><inlineCode parentName="a">{`step-certificates`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.freshports.org/security/step-cli/"
        }}><inlineCode parentName="a">{`step-cli`}</inlineCode></a></li>
    </ul>
    <p>{`Here's an `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/certificates/discussions/572#discussioncomment-751271"
      }}>{`installation transcript`}</a>{` for installing both packages in a FreeBSD jail.`}</p>
    <p>{`Big shout out to the maintainers of these packages! We appreciate you.`}</p>
    <h3>{`Linux binaries`}</h3>
    <p>{`We distribute pre-compiled binaries for amd64, arm64, armv6, armv7, mips, mips64, ppc64le, and 386.
See the `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/cli/releases/latest"
      }}>{`latest release`}</a>{` page.`}</p>
    <p>{`Here's how to download and install the `}<inlineCode parentName="p">{`step`}</inlineCode>{` and `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` binaries on an amd64 machine:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Install `}<inlineCode parentName="p">{`step`}</inlineCode></p>
        <p parentName="li">{`Download and install the Linux tarball from our `}<a parentName="p" {...{
            "href": "https://github.com/smallstep/cli/releases/latest"
          }}>{`latest release`}</a>{`:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`wget -O step.tar.gz https://dl.step.sm/gh-release/cli/docs-ca-install/v0.23.1/step_linux_0.23.1_amd64.tar.gz
tar -xf step.tar.gz
sudo cp step_0.23.1/bin/step /usr/bin
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Install `}<inlineCode parentName="p">{`step-ca`}</inlineCode></p>
        <p parentName="li">{`Download and install the Linux tarball from our `}<a parentName="p" {...{
            "href": "https://github.com/smallstep/certificates/releases/latest"
          }}>{`latest release`}</a>{`:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`wget -O step-ca.tar.gz https://dl.step.sm/gh-release/certificates/docs-ca-install/v0.23.1/step-ca_linux_0.23.1_amd64.tar.gz
tar -xf step-ca.tar.gz
sudo cp step-ca_0.23.1/bin/step-ca /usr/bin
`}</code></pre>
      </li>
    </ol>
    <p>{`To configure `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` as a daemon, see our `}<a parentName="p" {...{
        "href": "https://smallstep.com/docs/step-ca/certificate-authority-server-production#running-step-ca-as-a-daemon"
      }}>{`systemd configuration guide`}</a>{`.`}</p>
    <h3>{`Kubernetes`}</h3>
    <p>{`We publish `}<a parentName="p" {...{
        "href": "https://hub.helm.sh/charts/smallstep/step-certificates"
      }}>{`helm charts`}</a>{` for easy installation on kubernetes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`helm install step-certificates
`}</code></pre>
    <p>{`You can see all the configuration options at `}<a parentName="p" {...{
        "href": "https://hub.helm.sh/charts/smallstep/step-certificates"
      }}>{`https://hub.helm.sh/charts/smallstep/step-certificates`}</a>{`.`}</p>
    <Alert severity="info" mdxType="Alert">
  <AlertTitle mdxType="AlertTitle">Autocert</AlertTitle>
  <div>
    If you're using Kubernetes, make sure you <Link href="https://github.com/smallstep/autocert" mdxType="Link">check out autocert</Link>: a
    kubernetes add-on that builds on <Code mdxType="Code">step-ca</Code> to automatically inject
    TLS/HTTPS certificates into your containers.
  </div>
    </Alert>
    <h3>{`Docker`}</h3>
    <p>{`We publish `}<a parentName="p" {...{
        "href": "https://hub.docker.com/r/smallstep/step-ca"
      }}>{`docker images`}</a>{` for easy container installation.`}</p>
    <h3>{`Test your installation`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step version
Smallstep CLI/0.10.0 (darwin/amd64)
Release Date: 2019-04-30 19:01 UTC

$ step-ca version
Smallstep CA/0.10.0 (darwin/amd64)
Release Date: 2019-04-30 19:02 UTC
`}</code></pre>
    <h3>{`Next Steps`}</h3>
    <ul>
      <li parentName="ul">{`Learn about the `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/certificate-authority-core-concepts"
        }}>{`core concepts and design principles`}</a>{` behind `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Read the `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/getting-started"
        }}>{`Getting Started guide`}</a>{` to set up a CA and get your first certificate.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      